export const activities = [
    "Sin Actividad",
    "Tenedora de Activos",
    "A - AGRICULTURA, GANADERÍA, CAZA, SILVICULTURA, PESCA Y ACTIVIDADES DE SERVICIOS CONEXAS",
    "A01 - Agricultura, ganadería, caza y actividades de servicios conexas",
    "A011 - Cultivo de productos no perennes",
    "A0111 - Cultivo de cereales (excepto arroz y maíz), legumbres y semillas oleaginosas",
    "A0112 - Cultivo de arroz",
    "A0113 - Cultivo de vegetales, raíces y tubérculos",
    "A0114 - Cultivo de ca?a de azúcar",
    "A0115 - Cultivo de tabaco",
    "A0116 - Cultivo de fibras",
    "A0117 - Cultivo de maíz",
    "A0119 - Cultivo de otros productos no perennes",
    "A012 - Cultivo de productos perennes",
    "A0122 - Cultivo de frutas tropicales y subtropicales",
    "A0125 - Cultivo de otras frutas que crecen en árboles y/o arbustos y/o nueces",
    "A0126 - Cultivo de frutas oleaginosas",
    "A0127 - Cultivo de productos para preparar bebidas",
    "A0129 - Otros cultivos perennes",
    "A013 - Propagación de plantas",
    "A0130 - Propagación de plantas",
    "A014 - Ganadería",
    "A0141 - Cría de ganado vacuno y búfalos",
    "A0142 - Cría de caballos y otros equinos",
    "A0144 - Cría de ovejas y cabras",
    "A0145 - Cría de cerdos / puercos",
    "A0146 - Cría de aves de corral y obtención de subproductos",
    "A0149 - Cría de otros animales",
    "A015 - Explotación mixta",
    "A0150 - Explotación mixta",
    "A016 - Actividades de apoyo a la agricultura, ganadería",
    "A0161 - Actividades de apoyo a los cultivos y posteriores a la cosecha",
    "A0162 - Actividades de apoyo a la ganadería",
    "A0164 - Procesamiento de semillas para la propagación",
    "A017 - Caza ordinaria, mediante trampas y actividades de servicios conexas",
    "A0170 - Caza ordinaria, mediante trampas y actividades de servicios conexas",
    "A02 - Silvicultura y extracción de madera",
    "A021 - Silvicultura y otras actividades relacionadas a la silvicultura",
    "A0210 - Silvicultura y otras actividades relacionadas a la silvicultura",
    "A022 - Extracción de madera",
    "A0220 - Extracción de madera",
    "A023 - Recolección de productos forestales diferentes a la madera",
    "A0230 - Recolección de productos forestales diferentes a la madera",
    "A024 - Servicios de apoyo a la silvicultura",
    "A0240 - Servicios de apoyo a la silvicultura",
    "A03 - Pesca y Acuicultura",
    "A031 - Pesca",
    "A0311 - Pesca marítima oceánicas o costeras",
    "A0312 - Pesca de aguas dulce",
    "A032 - Acuicultura",
    "A0321 - Acuicultura marítima",
    "A0322 - Acuicultura de agua dulce",
    "A05 - ACTPRU",
    "B - EXPLOTACIÓN DE MINAS Y CANTERAS",
    "B05 - Extracción de carbón y lignito, extracción de turba",
    "B051 - Extracción y aglomeración de carbón de piedra",
    "B0510 - Extracción y aglomeración de carbón de piedra",
    "B052 - Extracción y aglomeración de lignito",
    "B0520 - Extracción y aglomeración de lignito",
    "B06 - Extracción de petróleo crudo y gas natural",
    "B061 - Extracción de petróleo crudo",
    "B0610 - Extracción de petróleo crudo",
    "B062 - Extracción de gas natural",
    "B0620 - Extracción de gas natural",
    "B07 - Extracción de minerales metalíferos",
    "B071 - Extracción de minerales de hierro",
    "B0710 - Extracción de minerales de hierro",
    "B072 - Extracción de minerales metalíferos no ferrosos",
    "B0721 - Extracción de minerales de uranio y torio",
    "B0729 - Extracción de otros minerales metalíferos no ferrosos",
    "B08 - Explotación de otras minas y cantera",
    "B081 - Extracción de piedra, arena y arcilla",
    "B0810 - Extracción de piedra, arena y arcilla",
    "B089 - Explotación de minas y canteras n.c.p.",
    "B0891 - Extracción de minerales para la fabricación de fertilizantes y productos químicos",
    "B0892 - Extracción de turba",
    "B0893 - Extracción de sal",
    "B0899 - Explotación de otras minas y canteras n.c.p.",
    "B09 - Actividades de apoyo a la explotación de minas",
    "B091 - Actividades de apoyo a la extracción de petróleo y gas natural",
    "B0910 - Actividades de apoyo a la extracción de petróleo y gas natural",
    "B099 - Actividades de apoyo a la explotación de otras minas y canteras",
    "B0990 - Actividades de apoyo a la explotación de otras minas y canteras",
    "C - INDUSTRIAS MANUFACTURERAS",
    "C10 - Elaboración de productos alimenticios",
    "C101 - Procesamiento y conservación de carne",
    "C1010 - Procesamiento y conservación de carne",
    "C102 - Procesamiento y conservación de pescados, crustáceos y moluscos",
    "C1020 - Procesamiento y conservación de pescados, crustáceos y moluscos",
    "C103 - Procesamiento y conservación de frutas y vegetales",
    "C1030 - Procesamiento y conservación de frutas y vegetales",
    "C104 - Elaboración de aceites y grasas de origen vegetal y animal",
    "C1040 - Elaboración de aceites y grasas de origen vegetal y animal",
    "C105 - Elaboración de productos lácteos",
    "C1050 - Elaboración de productos lácteos",
    "C106 - Elaboración de productos de molinería, almidones y productos derivados del almidón",
    "C1061 - Elaboración de productos de molinería",
    "C1062 - Elaboración de almidones y productos elaborados del almidón",
    "C107 - Elaboración de otros productos alimenticios",
    "C1071 - Elaboración de productos de panadería",
    "C1072 - Elaboración de azúcar",
    "C1073 - Elaboración de cacao, chocolate y productos de confitería",
    "C1074 - Elaboración de macarrones, fideos, cuscús y productos farináceos similares",
    "C1075 - Elaboración de comidas y platos preparados",
    "C1079 - Elaboración de otros productos alimenticios n.c.p.",
    "C108 - Elaboración de alimentos preparados para animales",
    "C1080 - Elaboración de alimentos preparados para animales",
    "C11 - Elaboración de bebidas",
    "C110 - Elaboración de bebidas",
    "C1101 - Destilación, rectificación y mezcla de bebidas alcohólicas",
    "C1102 - Elaboración de vinos",
    "C1103 - Elaboración de bebidas malteadas ( cerveza y malta)",
    "C1104 - Elaboración de bebidas no alcohólicas; producción de aguas minerales y agua embotellada",
    "C12 - Elaboración de productos de tabaco",
    "C120 - Elaboración de productos de tabaco",
    "C1200 - Elaboración de productos de tabaco",
    "C1201 - Elaboración de productos de tabaco en Zonas Francas",
    "C13 - Fabricación de productos textiles",
    "C131 - Hiladura, tejedura y acabados de productos textiles",
    "C1311 - Preparación e hiladura de fibras textiles",
    "C1312 - Tejedura de productos textiles",
    "C1313 - Acabado de productos textiles (Serigrafía)",
    "C139 - Fabricación de otros productos textiles",
    "C1391 - Fabricación de tejidos y tela de punto (Crochet o Ganchillo)",
    "C1392 - Fabricación de artículos confeccionados con textiles, excepto prendas de vestir",
    "C1393 - Fabricación de tapices y alfombras",
    "C1394 - Fabricación de cuerdas, cordeles, bramantes y redes",
    "C1399 - Fabricación de otros textiles n.c.p.",
    "C14 - Fabricación de prendas de vestir",
    "C141 - Fabricación de prendas de vestir, excepto prendas de piel",
    "C1411 - Fabricación de prendas de vestir, excepto prendas de piel",
    "C1412 - Fabricación de prendas de vestir, excepto prendas de piel en Zonas Francas",
    "C142 - Fabricación de artículos de piel",
    "C1420 - Fabricación de artículos de piel",
    "C143 - Fabricación de prendas de tejidos y de punto y ganchillo",
    "C1430 - Fabricación de prendas de tejidos de punto y ganchillo o crochet (aguja de tejer)",
    "C15 - Fabricación de cueros y productos conexos",
    "C151 - Preparación, curtido y acabado del cuero; fabricación de maletas, bolsos de mano, artículos de talabartería o guarnicionería; preparación y te?ido de pieles",
    "C1511 - Curtido y adobo de cueros; adobo y te?ido de pieles",
    "C1512 - Fabricación de maletas, bolsos de mano, y artículos de talabartería y guarnicionería",
    "C152 - Fabricación de calzado",
    "C1520 - Fabricación de calzado",
    "C16 - Producción de madera y fabricación de productos de madera y corcho, excepto muebles; fabricación de artículos de paja y de materiales trenzables",
    "C161 - Aserrados y acepilladura de madera",
    "C1610 - Aserrados y acepilladura de madera",
    "C162 - Fabricación de productos de madera, corcho, paja y materiales trenzables",
    "C1621 - Fabricación de hojas de madera para enchapado, fabricación de tableros laminados, tablas de partículas y otros tableros y paneles",
    "C1622 - Fabricación de partes y piezas de carpintería para edificios y construcciones",
    "C1623 - Fabricación de recipientes de madera",
    "C1629 - Fabricación de otros productos de madera; fabricación de artículos de corcho, paja y materiales trenzables",
    "C17 - Fabricación de papel y de los productos de papel",
    "C170 - Fabricación de papel y productos de papel",
    "C1701 - Fabricación de pulpa, papel y cartón",
    "C1702 - Fabricación de papel, cartón ondulado y envases de papel y cartón",
    "C1709 - Fabricación de otros artículos del papel y cartón",
    "C18 - Actividades de impresión y reproducción de grabaciones",
    "C181 - Actividades de impresión y servicios conexos",
    "C1811 - Actividades de impresión (periódicos, revistas y otros)",
    "C1812 - Servicios relacionados con la impresión",
    "C182 - Reproducción de grabaciones",
    "C1820 - Reproducción de grabaciones",
    "C19 - Fabricación de coque y de productos de la refinación del petróleo",
    "C191 - Fabricación de productos de horno de coque",
    "C1910 - Fabricación de productos de horno de coque",
    "C192 - Fabricación de los productos de la refinación del petróleo",
    "C1920 - Fabricación de los productos de la refinación del petróleo",
    "C20 - Fabricación de sustancias y productos químicos",
    "C201 - Fabricación de sustancias químicas básicas, abonos y compuestos de nitrógeno, plásticos y caucho sintético en formas primarias",
    "C2011 - Fabricación de sustancias químicas básicas",
    "C2012 - Fabricación abonos y compuestos de nitrógeno",
    "C2013 - Fabricación de plásticos y de caucho sintético en formas primarias",
    "C202 - Fabricación de otros productos químicos",
    "C2021 - Fabricación de pesticidas y de otros productos químicos de uso agropecuario",
    "C2022 - Fabricación de pinturas, barnices y productos de revestimiento similares, tintas de imprenta y masillas",
    "C2023 - Fabricación de jabones, detergentes preparados para limpiar y pulir, perfumes, preparados de tocador y velas",
    "C2029 - Fabricación de otros productos químicos, n.c.p.",
    "C203 - Fabricación de fibras manufacturadas",
    "C2030 - Fabricación de fibras artificiales y sintéticas",
    "C21 - Fabricación de productos farmacéuticos, sustancias químicas medicinales y de productos botánicos",
    "C210 - Fabricación de productos farmacéuticos, sustancias químicas medicinales y de productos botánicos",
    "C2100 - Fabricación de productos farmacéuticos, sustancias químicas medicinales y de productos botánicos",
    "C2101 - Fabricación de productos farmacéuticos, sustancias químicas medicinales y de productos botánicos en Zonas francas",
    "C22 - Fabricación de productos de caucho y plástico",
    "C221 - Fabricación de productos de caucho",
    "C2211 - Fabricación de cubiertas y cámaras de caucho; reencauche (renovación de cubiertas de caucho)",
    "C2219 - Fabricación de otros productos de caucho",
    "C222 - Fabricación de productos de plástico",
    "C2220 - Fabricación de productos de plástico",
    "C23 - Fabricación de otros productos minerales no metálicos",
    "C231 - Fabricación de vidrio y de productos de vidrio",
    "C2310 - Fabricación de vidrio y de productos de vidrio",
    "C239 - Fabricación de productos minerales no metálicos, n.c.p.",
    "C2391 - Fabricación de productos refractarios",
    "C2392 - Fabricación de materiales de arcilla para la construcción",
    "C2393 - Fabricación de otros productos de cerámica y porcelana",
    "C2394 - Fabricación de cemento, cal y yeso",
    "C2395 - Fabricación de artículos de hormigón, cemento, yeso y panalit",
    "C2396 - Corte, tallado y acabado de la piedra",
    "C2399 - Fabricación de otros productos minerales no metálicos, n.c.p.",
    "C24 - Fabricación de metales comunes",
    "C241 - Industrias básicas de hierro y acero básicos",
    "C2410 - Fabricación de productos básicos de hierro y acero",
    "C242 - Fabricación de productos primarios de metales preciosos y metales no ferrosos",
    "C2420 - Fabricación de productos primarios de metales preciosos y metales no ferrosos",
    "C243 - Fundición de metales",
    "C2431 - Fundición de hierro y acero",
    "C2432 - Fundición de metales no ferrosos",
    "C25 - Fabricación de productos derivados del metal, excepto maquinaria y equipo",
    "C251 - Fabricación de productos metálicos para uso estructural, tanques, depósitos y los generadores de vapor",
    "C2511 - Fabricación de productos metálicos para uso estructural (soldadura)",
    "C2512 - Fabricación de tanques, depósitos y recipientes de metal",
    "C2513 - Fabricación de los generadores del vapor, excepto calderas de agua caliente para calefacción central",
    "C252 - Fabricación de armas y municiones",
    "C2520 - Fabricación de armas y municiones",
    "C259 - Fabricación de otros productos elaborados de metal; actividades de trabajo de metales",
    "C2591 - Forja, prensado, estampado y laminado de metales; pulvimetalurgia",
    "C2592 - Tratamiento y revestimiento de metales",
    "C2593 - Fabricación de artículos de cuchillería, herramientas de mano y artículos de ferretería, llaves",
    "C2594 - Fabricación de otros productos de metal en Zonas Francas",
    "C2599 - Fabricación de otros productos de metal, n.c.p.",
    "C26 - Fabricación de los productos informáticos, electrónicos y ópticos",
    "C261 - Fabricación de productos electrónicos",
    "C2610 - Fabricación de productos electrónicos",
    "C262 - Fabricación de computadoras y equipo periférico",
    "C2620 - Fabricación de computadoras y equipo periférico",
    "C263 - Fabricación de equipos de comunicaciones",
    "C2630 - Fabricación de equipos de comunicaciones",
    "C264 - Fabricación de aparatos de consumo electrónico",
    "C2640 - Fabricación de aparatos de consumo electrónico, de audio y video",
    "C265 - Fabricación de equipos para medir, verificar, navegar, equipos de control y relojes de todo tipo",
    "C2651 - Fabricación de equipos para medir, verificar, y navegar y de equipos de control",
    "C2652 - Fabricación de todo tipo de relojes",
    "C266 - Fabricación de equipos radiológicos, electromédicos y electro terapéuticos",
    "C2660 - Fabricación de equipos radiológicos, electromédicos y electro terapéuticos",
    "C267 - Fabricación de instrumentos ópticos y equipo fotográfico",
    "C2670 - Fabricación de instrumentos ópticos y equipo fotográfico",
    "C268 - Fabricación de soportes magnéticos y ópticos",
    "C2680 - Fabricación de soportes magnéticos y ópticos",
    "C27 - Fabricación de equipo eléctrico y de uso doméstico",
    "C271 - Fabricación de motores eléctricos, generadores, transformadores eléctricos, distribución de la electricidad y aparato del control",
    "C2710 - Fabricación de motores eléctricos, generadores, transformadores eléctricos, distribución de la electricidad y aparato de control",
    "C272 - Fabricación de baterías y acumuladores",
    "C2720 - Fabricación de baterías y acumuladores",
    "C273 - Fabricación de cables y dispositivos de cableado",
    "C2731 - Fabricación de cables de fibra óptica",
    "C2732 - Fabricación de otros cables eléctricos y electrónicos",
    "C2733 - Fabricación de dispositivos de cableado",
    "C274 - Fabricación de equipos de iluminación eléctricos y no eléctricos",
    "C2740 - Fabricación de equipos de iluminación eléctrica y no eléctrica",
    "C275 - Fabricación de aparatos de uso doméstico",
    "C2750 - Fabricación de aparatos de uso doméstico",
    "C279 - Fabricación de otros equipos eléctricos",
    "C2790 - Fabricación de otros equipos eléctricos",
    "C28 - Fabricación de la maquinaria y equipo, n.c.p.",
    "C281 - Fabricación de la maquinaria de uso general",
    "C2811 - Fabricación de motores y turbinas, excepto motores para aeronaves, vehículos automotores y motocicletas",
    "C2812 - Fabricación de equipos hidráulicos",
    "C2813 - Fabricación de bombas, compresores, grifos y válvulas",
    "C2814 - Fabricación de cojinetes, engranajes, trenes de engranajes y piezas de transmisión",
    "C2815 - Fabricación de hornos, hogares y quemadores",
    "C2816 - Fabricación de equipo de elevación y manipulación",
    "C2817 - Fabricación de maquinaria y equipo de oficina",
    "C2818 - Fabricación de herramientas manuales",
    "C2819 - Fabricación de otros tipos de maquinaria de uso general",
    "C282 - Fabricación de maquinaria de uso especial",
    "C2821 - Fabricación de maquinaria agropecuaria y forestal",
    "C2822 - Fabricación de maquinarias de metal, y máquinas herramientas",
    "C2823 - Fabricación de maquinaria para metalúrgica",
    "C2824 - Fabricación de maquinaria para explotación de minas y canteras y para obras de construcción",
    "C2825 - Fabricación de maquinaria para la elaboración de alimentos, bebidas y tabaco",
    "C2826 - Fabricación de maquinaria para la elaboración de productos textiles, prendas de vestir y cueros",
    "C2829 - Fabricación de otros tipos de maquinaria de uso especial",
    "C29 - Fabricación de vehículos automotores, remolques y semirremolques",
    "C291 - Fabricación de vehículos automotores",
    "C2910 - Fabricación de vehículos automotores",
    "C292 - Fabricación de carrocerías para vehículos automotores; fabricación de remolques y semirremolques",
    "C2920 - Fabricación de carrocerías para vehículos automotores; fabricación de remolques y semirremolques",
    "C293 - Fabricación de partes y accesorios para motores de vehículos",
    "C2930 - Fabricación de partes y accesorios para motores de vehículos",
    "C30 - Fabricación de otros tipos de equipo de transporte",
    "C301 - Construcción de buques y otras embarcaciones",
    "C3011 - Construcción de buques y estructuras flotantes",
    "C3012 - Construcción de embarcaciones de recreo y deporte",
    "C302 - Fabricación de locomotoras y de material rodante",
    "C3020 - Fabricación de locomotoras y de material rodante",
    "C303 - Fabricación de aeronaves y naves espaciales y de maquinaria conexa",
    "C3030 - Fabricación de aeronaves y naves espaciales y de maquinaria conexa",
    "C304 - Fabricación de vehículos militares de combate",
    "C3040 - Fabricación de vehículos militares de combate",
    "C309 - Fabricación de tipos de equipo de transporte, n.c.p.",
    "C3091 - Fabricación de motocicletas",
    "C3092 - Fabricación de bicicletas y sillas de ruedas",
    "C3099 - Fabricación de otros tipos de equipo de transporte, n.c.p.",
    "C31 - Fabricación de muebles",
    "C310 - Fabricación de muebles y colchones (tapicerías)",
    "C3100 - Fabricación de muebles y colchones (tapicerías)",
    "C32 - Otras industrias manufactureras",
    "C321 - Fabricación de joyas, bisutería y artículos conexos",
    "C3211 - Fabricación de joyas y artículos conexos",
    "C3212 - Fabricación de joyas de imitación y artículos conexos",
    "C322 - Fabricación de instrumentos musicales",
    "C3220 - Fabricación de instrumentos musicales",
    "C323 - Fabricación de artículos de deporte",
    "C3230 - Fabricación de artículos de deporte",
    "C324 - Fabricación de juegos y juguetes (excepto bicicletas y triciclos de metal)",
    "C3240 - Fabricación de juegos y juguetes (excepto bicicletas y triciclos de metal)",
    "C325 - Fabricación de instrumentos y suministros médicos y dentales",
    "C3250 - Fabricación de instrumentos y suministros médicos y dentales",
    "C329 - Otras industrias manufactureras, n.c.p..",
    "C3290 - Otras industrias manufactureras, n.c.p..",
    "C33 - Reparación, mantenimiento e instalación de maquinaria y equipo comercial e industrial",
    "C331 - Reparación y mantenimiento de productos elaborados de metal, maquinaria y equipos",
    "C3311 - Reparación y mantenimiento de productos elaborados de metal",
    "C3312 - Reparación y mantenimiento de maquinaria y equipo",
    "C3313 - Reparación y mantenimiento de equipo electrónico y óptico",
    "C3314 - Reparación y mantenimiento de equipo eléctrico",
    "C3315 - Reparación y mantenimiento de equipo de transporte, excepto los vehículos automotores",
    "C3319 - Reparación de equipo de otro tipo",
    "C332 - Instalación de maquinaria y equipo industrial",
    "C3320 - Instalación de maquinaria y equipo industrial",
    "D - SUMINISTRO DE ELECTRICIDAD, GAS, VAPOR Y AIRE ACONDICIONADO",
    "D3 - Suministro de electricidad, gas, vapor y aire acondicionado",
    "D35 - Suministro de electricidad, gas, vapor y aire acondicionado",
    "D351 - Generación, transmisión y distribución de energía eléctrica",
    "D3510 - Generación, transmisión y distribución de energía eléctrica",
    "D352 - Fabricación del gas; distribución de combustibles gaseosos por tuberías",
    "D3520 - Fabricación del gas; distribución de combustibles gaseosos por tuberías",
    "D353 - Suministro de vapor, aire acondicionado y producción de hielo",
    "D3530 - Suministro de vapor, aire acondicionado y producción de hielo",
    "E - SUMINISTRO DE AGUA; ALCANTARILLADO, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO",
    "E3 - Captaci?n, tratamiento y suministro de agua",
    "E36 - Captación, tratamiento y suministro de agua",
    "E360 - Captación, tratamiento y suministro de agua",
    "E3600 - Captación, tratamiento y suministro de agua",
    "E37 - Alcantarillado",
    "E370 - Alcantarillado",
    "E3700 - Alcantarillado",
    "E38 - Recolección, tratamiento y eliminación de desechos, recuperación de materiales",
    "E381 - Recolección de desechos",
    "E3811 - Recolección de desechos no peligrosos (basura)",
    "E3812 - Recolección de desechos peligrosos",
    "E382 - Tratamiento y eliminación de desechos",
    "E3821 - Tratamiento y eliminación de desechos no peligrosos",
    "E3822 - Tratamiento y eliminación de desechos peligrosos",
    "E383 - Procesamiento y recuperación de materiales",
    "E3830 - Procesamiento y recuperación de materiales",
    "E39 - Actividades de saneamiento y otros servicios de gestión de desechos",
    "E390 - Actividades de saneamiento y otros servicios de gestión de desechos",
    "E3900 - Actividades de saneamiento y otros servicios de gestión de desechos",
    "F - CONSTRUCCIÓN",
    "F4 - Construcci?n de edificios",
    "F41 - Construcción de edificios",
    "F410 - Construcción de edificios",
    "F4100 - Construcción de edificios",
    "F42 - Ingeniería Civil",
    "F421 - Construcción de Caminos y vías férreas",
    "F4210 - Construcción de Caminos y vías férreas",
    "F422 - Construcción de proyectos de servicios públicos",
    "F4220 - Construcción de proyectos de servicios públicos",
    "F429 - Construcción de otros proyectos de ingeniería civil",
    "F4290 - Construcción de otros proyectos de ingeniería civil",
    "F43 - Actividades especializadas de la construcción",
    "F431 - Demolición y preparación del terreno",
    "F4311 - Demolición de edificios y otras estructuras",
    "F4312 - Preparación del terreno",
    "F432 - Actividades de instalación eléctrica, fontanería y otras instalaciones de la construcción",
    "F4321 - Instalación eléctrica",
    "F4322 - Fontanería e instalación de calefacción y aire acondicionado",
    "F4329 - Otro tipo de instalaciones de construcción",
    "F433 - Terminación de edificios",
    "F4330 - Terminación de edificios",
    "F439 - Otras actividades especializadas de la construcción",
    "F4390 - Otras actividades especializadas de la construcción",
    "G - COMERCIO AL POR MAYOR Y AL POR MENOR (INCLUYE ZONAS FRANCAS), REPARACIÓN DE VEHÍCULOS DE MOTOR Y MOTOCICLETAS",
    "G4 - Comercio al por mayor en Zonas Francas",
    "G45 - Comercio al por mayor en Zonas Francas",
    "G451 - Venta al por mayor a cambio de una retribución o por contrata en Zonas Francas",
    "G4510 - Venta al por mayor a cambio de una retribución o por contrata en Zonas Francas",
    "G45101 - Agencias de importación y/o exportación comisionista en Zonas Francas",
    "G45102 - Corredores de productos básicos y mercancía en general en Zonas Francas",
    "G452 - Venta al por mayor de materias primas agropecuarias y animales vivos en Zonas Francas",
    "G4520 - Venta al por mayor de materias primas agropecuarias y animales vivos en Zonas Francas",
    "G45201 - Venta al por mayor de granos y semillas en Zonas Francas",
    "G45202 - Venta al por mayor de frutas oleaginosas en Zonas Francas",
    "G45203 - Venta al por mayor de flores y plantas en Zonas Francas",
    "G45204 - Venta al por mayor de tabaco no manufacturado en Zonas Francas",
    "G45205 - Venta al por mayor de animales vivos en Zonas Francas",
    "G45206 - Venta al por mayor de cueros y pieles en Zonas Francas",
    "G45207 - Venta al por mayor de materiales agrícolas, desechos, residuos y productos usados para alimentar animales en Zonas Francas",
    "G45209 - Venta al por mayor de otras materias primas agropecuarias y animales vivos, n.c.p. en Zonas Francas",
    "G453 - Venta al por mayor de alimentos, bebidas y tabaco en Zonas Francas",
    "G4530 - Venta al por mayor de alimentos, bebidas y tabaco en Zonas Francas",
    "G45301 - Venta al por mayor de alimentos, excepto carnes, aves de corral y pescado en Zonas Francas",
    "G45302 - Venta al por mayor de productos lácteos y sus derivados en Zonas Francas",
    "G45303 - Venta al por mayor de carnes y productos cárnicos (excepto aves de corral y sus productos) en Zonas Francas",
    "G45304 - Venta al por mayor de aves de corral y sus productos en Zonas Francas",
    "G45305 - Venta al por mayor de pescado, mariscos y productos conexos en Zonas Francas",
    "G45306 - Venta al por mayor de bebidas alcohólicas (incluye cerveza) en Zonas Francas",
    "G45307 - Venta al por mayor de bebidas no alcohólicas y agua mineral en Zonas Francas",
    "G45308 - Venta al por de mayor de productos del tabaco en Zonas Francas",
    "G45309 - Venta al por mayor de alimentos, bebidas y tabaco, n.c.p. en Zonas Francas",
    "G454 - Venta al por mayor de efectos personales, enseres domésticos, artículos de joyerías, relojes y productos diversos para el consumidor en Zonas Francas",
    "G4541 - Venta al por mayor de textiles, prendas de vestir sus accesorios y calzado en Zonas Francas",
    "G45411 - Venta al por mayor de hilo, telas y artículos de lencería para el hogar en Zonas Francas",
    "G45412 - Venta al por mayor de artículos de mercería: agujas, hilos de costura, etc. en Zonas Francas",
    "G45413 - Venta al por mayor de prendas de vestir, incluso prendas de vestir deportiva y accesorios (excepto calzado) en Zonas Francas",
    "G45414 - Venta al por mayor de calzado en Zonas Francas",
    "G45415 - Venta al por mayor de artículos de piel en Zonas Francas",
    "G45419 - Venta al por mayor de textiles, prendas de vestir, sus accesorios y calzados, n.c.p. en Zonas Francas",
    "G4542 - Venta al por mayor de enseres domésticos en Zonas Francas",
    "G45421 - Venta al por mayor de muebles de hogar, electrodomésticos: (radios, equipos de TV, grabadoras, reproductores de CD y DVD, estereos, videojuegos) y equipo de iluminación en Zonas Francas",
    "G45422 - Venta al por mayor de cubiertos; artículos de porcelana, cristalería; utensilios de madera, corcho y de mimbre en Zonas Francas",
    "G45429 - Venta al por mayor de enseres domésticos, n.c.p. en Zonas Francas",
    "G4543 - Venta al por mayor de art?culos de joyer?a, relojes y fantas?a fina en Zonas Francas",
    "G45430 - Venta al por mayor de art?culos de joyer?a, relojes y fantas?a fina en Zonas Francas",
    "G4549 - Venta al por mayor de otros efectos personales y productos diversos para el consumidor en Zonas Francas",
    "G45491 - Venta al por mayor de productos farmacéuticos y medicinales, cosméticos y artículos de tocador en Zonas Francas",
    "G45492 - Venta al por mayor de bicicletas, sus partes y accesorios en Zonas Francas",
    "G45493 - Venta al por mayor de libros, periódicos, revistas, papel y útiles de escritorio en Zonas Francas",
    "G45494 - Venta al por mayor de videos y audio grabados, CD y DVD en Zonas Francas",
    "G45495 - Venta al por mayor de artículos fotográficos y ópticos (lentes de sol, binoculares y lupas) en Zonas Francas",
    "G45496 - Venta al por mayor de artículos de cueros y accesorios de viajes en Zonas Francas",
    "G45499 - Venta al por mayor de otros efectos personales y productos diversos para el consumidor, n.c.p. en Zonas Francas",
    "G455 - Venta al por mayor de maquinaria, equipos y materiales; vehículos automotores y motocicletas sus partes, piezas y accesorios en Zonas Francas",
    "G4551 - Venta al por mayor de computadoras, equipo informático periférico y programas informáticos en Zonas Francas",
    "G45511 - Venta al por mayor de computadoras y equipo computacional periférico en Zonas Francas",
    "G45512 - Venta al por mayor de software en Zonas Francas",
    "G4552 - Venta al por mayor de equipo electr?nico, telecomunicaciones sus partes y piezas en Zonas Francas",
    "G45520 - Venta al por mayor de equipo electr?nico, telecomunicaciones sus partes y piezas en Zonas Francas",
    "G4553 - Venta al por mayor de maquinaria, equipo agrícola, sus partes, piezas y accesorios en Zonas Francas",
    "G45531 - Venta al por mayor de maquinaria y equipo agrícola en Zonas Francas",
    "G45532 - Venta al por mayor de tractores usados en Zonas Francas",
    "G45539 - Venta al por mayor de partes, piezas y accesorios de maquinaria y equipo agrícola en Zonas Francas",
    "G4554 - Venta al por mayor de veh?culos automotores en Zonas Francas",
    "G45540 - Venta al por mayor de veh?culos automotores en Zonas Francas",
    "G4555 - Venta al por mayor de partes, piezas y accesorios de veh?culos automotores en Zonas Francas",
    "G45550 - Venta al por mayor de partes, piezas y accesorios de veh?culos automotores en Zonas Francas",
    "G4556 - Venta al por mayor de motocicletas, sus partes, piezas y accesorios en Zonas Francas",
    "G45560 - Venta al por mayor de motocicletas, sus partes, piezas y accesorios en Zonas Francas",
    "G4559 - Venta al por mayor de otro tipo de maquinaria y equipo en Zonas Francas",
    "G45591 - Venta al por mayor de maquinaria y equipo de oficina (excepto computadoras y equipo periférico computacional) en Zonas Francas",
    "G45592 - Venta al por mayor de muebles de oficina en Zonas Francas",
    "G45593 - Venta al por mayor de equipo de transporte (excepto vehículos motorizados, motocicletas y bicicletas) en Zonas Francas",
    "G45594 - Venta al por mayor de alambres e interruptores y otros equipos para uso industrial en Zonas Francas",
    "G45595 - Venta al por mayor de otros materiales eléctricos, como motores eléctricos y transformadores en Zonas Francas",
    "G45596 - Venta al por mayor de maquinas, herramientas de cualquier tipo y para cualquier material en Zonas Francas",
    "G45599 - Venta al por mayor de otro tipo de maquinaria y equipo, n.c.p. en Zonas Francas",
    "G456 - Venta al por mayor especializada en Zonas Francas",
    "G4561 - Venta al por mayor de combustibles s?lidos, l?quidos y gaseosos y de productos conexos en Zonas Francas",
    "G45610 - Venta al por mayor de combustibles s?lidos, l?quidos y gaseosos y de productos conexos en Zonas Francas",
    "G4562 - Venta al por mayor de metales y de minerales metalíferos en Zonas Francas",
    "G45621 - Venta al por mayor de minerales ferrosos y no ferrosos; metales ferrosos y no ferrosos en forma primarias; productos metálicos semiterminados ferrosos y no ferrosos en Zonas Francas",
    "G45622 - Venta al por mayor de oro y otros metales preciosos en Zonas Francas",
    "G4563 - Venta al por mayor de materiales de construcción, artículos de ferretería, equipo y materiales de fontanería y calefacción en Zonas Francas",
    "G45631 - Venta al por mayor de materiales de construcción en Zonas Francas",
    "G45632 - Venta al por mayor de artículos de ferretería y accesorios eléctricos en Zonas Francas",
    "G45639 - Venta al por mayor de materiales de construcción, artículos de ferretería, equipo y materiales de fontanería y calefacción, n.c.p. en Zonas Francas",
    "G4569 - Venta al por mayor de basura, desecho y otros productos, n.c.p. en Zonas Francas",
    "G45690 - Venta al por mayor de basura, desecho y otros productos, n.c.p. en Zonas Francas",
    "G459 - Venta al por mayor de otros productos no especializado en Zonas Francas",
    "G4590 - Venta al por mayor de otros productos no especializado en Zonas Francas",
    "G45900 - Venta al por mayor de otros productos no especializado en Zonas Francas",
    "G46 - Comercio al por mayor, en comisión y de vehículos automotores",
    "G461 - Venta al por mayor a cambio de una retribución o por contrata",
    "G4610 - Venta al por mayor a cambio de una retribución o por contrata",
    "G46101 - Agencias de importación y/o exportación comisionista",
    "G46102 - Corredores de productos básicos y mercancía en general",
    "G462 - Venta al por mayor de materias primas agropecuarias y animales vivos",
    "G4620 - Venta al por mayor de materias primas agropecuarias y animales vivos",
    "G46201 - Venta al por mayor de granos y semillas",
    "G46202 - Venta al por mayor de frutas oleaginosas",
    "G46203 - Venta al por mayor de flores y plantas",
    "G46204 - Venta al por mayor de tabaco no manufacturado",
    "G46205 - Venta al por mayor de animales vivos",
    "G46206 - Venta al por mayor de cueros y pieles",
    "G46207 - Venta al por mayor de materiales agrícolas, desechos, residuos y productos usados para alimentar animales",
    "G46209 - Venta al por mayor de otras materias primas agropecuarias y animales vivos n.c.p.",
    "G463 - Venta al por mayor de alimentos, bebidas y tabaco",
    "G4630 - Venta al por mayor de alimentos, bebidas y tabaco",
    "G46301 - Venta al por mayor de alimentos, excepto carnes, aves de corral y pescado",
    "G46302 - Venta al por mayor de productos lácteos y sus derivados",
    "G46303 - Venta al por mayor de carnes y productos cárnicos (excepto aves de corral y sus productos)",
    "G46304 - Venta al por mayor de aves de corral y sus productos",
    "G46305 - Venta al por mayor de pescado, mariscos y productos conexos",
    "G46306 - Venta al por mayor de bebidas alcohólicas (incluye cerveza)",
    "G46307 - Venta al por mayor de bebidas no alcohólicas y agua mineral",
    "G46308 - Venta al por de mayor de productos del tabaco",
    "G46309 - Venta al por mayor de alimentos, bebidas y tabaco n.c.p.",
    "G464 - Venta al por mayor de efectos personales, enseres domésticos, artículos de joyerías, relojes y productos diversos para el consumidor",
    "G4641 - Venta al por mayor de textiles, prendas de vestir sus accesorios y calzado",
    "G46411 - Venta al por mayor de hilo, telas y artículos de lencería para el hogar",
    "G46412 - Venta al por mayor de artículos de mercería: agujas, hilos de costura, etc.",
    "G46413 - Venta al por mayor de prendas de vestir, incluso prendas de vestir deportiva y accesorios (excepto calzado)",
    "G46414 - Venta al por mayor de calzado",
    "G46415 - Venta al por mayor de artículos de piel",
    "G46419 - Venta al por mayor de textiles, prendas de vestir, sus accesorios y calzado, n.c.p.",
    "G4642 - Venta al por mayor de otros enseres domésticos",
    "G46421 - Venta al por mayor de muebles de hogar, electrodomésticos, radios, equipos de TV, grabadoras, reproductores de CD, DVD y MP4, estéreos, videojuegos y equipo de iluminación, etc.",
    "G46422 - Venta al por mayor de cubiertos; artículos de porcelana y cristalería; utensilios de madera, corcho y de mimbre etc.",
    "G46429 - Venta al por mayor de enseres domésticos, n.c.p.",
    "G4643 - Venta al por mayor de art?culos de joyer?a , relojes y fantas?a fina",
    "G46430 - Venta al por mayor de art?culos de joyer?a , relojes y fantas?a fina",
    "G4649 - Venta al por mayor de otros efectos personales y productos diversos para el consumidor",
    "G46491 - Venta al por mayor de productos farmacéuticos y medicinales, cosméticos y artículos de tocador",
    "G46492 - Venta al por mayor de bicicletas, sus partes y accesorios",
    "G46493 - Venta al por mayor de libros, periódicos, revistas, papel y útiles de escritorio",
    "G46494 - Venta al por mayor de videos y audio grabados, CD y DVD",
    "G46495 - Venta al por mayor de artículos fotográficos y ópticos (lentes de sol, binoculares y lupas)",
    "G46496 - Venta al por mayor de artículos de cueros y accesorios de viajes",
    "G46499 - Venta al por mayor de otros efectos personales y productos diversos para el consumidor n.c.p.",
    "G465 - Venta al por mayor de maquinaria equipos y materiales; vehículos automotores y motocicletas; sus partes, piezas y accesorios",
    "G4651 - Venta al por mayor de computadoras, equipo informático periférico y programas informáticos",
    "G46511 - Venta al por mayor de computadoras y equipo computacional periférico",
    "G46512 - Venta al por mayor de software",
    "G4652 - Venta al por mayor de equipo electr?nico de telecomunicaciones, sus partes y piezas",
    "G46520 - Venta al por mayor de equipo electr?nico de telecomunicaciones, sus partes y piezas",
    "G4653 - Venta al por mayor de maquinaria, equipo agrícola; sus partes, piezas y accesorios",
    "G46531 - Venta al por mayor de maquinaria y equipo agrícola",
    "G46532 - Venta al por mayor de tractores usados",
    "G46539 - Venta al por mayor de partes, piezas y accesorios de maquinaria y equipo agrícola",
    "G4654 - Venta al por mayor de veh?culos automotores",
    "G46540 - Venta al por mayor de veh?culos automotores",
    "G4655 - Venta al por mayor de partes, piezas y accesorios de veh?culos automotores",
    "G46550 - Venta al por mayor de partes, piezas y accesorios de veh?culos automotores",
    "G4656 - Venta al por mayor de motocicletas, sus partes, piezas y accesorios",
    "G46560 - Venta al por mayor de motocicletas, sus partes, piezas y accesorios",
    "G4659 - Venta al por mayor de otro tipo de maquinaria y equipo",
    "G46591 - Venta al por mayor de maquinaria y equipo de oficina (excepto computadoras y equipo periférico computacional)",
    "G46592 - Venta al por mayor de muebles de oficina",
    "G46593 - Venta al por mayor de equipo de transporte, (excepto vehículos motorizados, motocicletas y bicicletas)",
    "G46594 - Venta al por mayor de alambres e interruptores y otros equipos para uso industrial",
    "G46595 - Venta al por mayor de otros materiales eléctricos, como motores eléctricos, transformadores",
    "G46596 - Venta al por mayor de máquinas, herramientas de cualquier tipo y para cualquier material",
    "G46599 - Venta al por mayor de otro tipo de maquinaria y equipo, n.c.p.",
    "G466 - Venta al por mayor especializada",
    "G4661 - Venta al por mayor de combustibles s?lidos, l?quidos y gaseosos y de productos conexos",
    "G46610 - Venta al por mayor de combustibles s?lidos, l?quidos y gaseosos y de productos conexos",
    "G4662 - Venta al por mayor de metales y de minerales metalíferos",
    "G46621 - Venta al por mayor de minerales ferrosos y no ferrosos; metales ferrosos y no ferrosos en forma primarias; productos metálicos semiterminados ferrosos y no ferrosos",
    "G46622 - Venta al por mayor de oro y otros metales preciosos",
    "G4663 - Venta al por mayor de materiales de construcción, artículos de ferretería, equipo y materiales de fontanería y calefacción",
    "G46631 - Venta al por mayor de materiales de construcción",
    "G46632 - Venta al por mayor de artículos de ferretería y accesorios eléctricos",
    "G46639 - Venta al por mayor de materiales de construcción, artículos de ferretería, equipo y materiales de fontanería y calefacción n.c.p.",
    "G4669 - Venta al por mayor de basura, desecho y otros productos, n.c.p.",
    "G46691 - Venta al por mayor de productos químicos industriales, fertilizantes y de productos agroquímicos",
    "G46692 - Venta al por mayor de materiales plásticos y de caucho",
    "G46693 - Venta al por mayor de fibras textiles",
    "G46694 - Venta al por mayor de papel granel",
    "G46695 - Venta al por mayor de piedras preciosas",
    "G46696 - Venta al po mayor de desechos metálicos y no metálicos para reciclar. (Incluye: recolección, clasificado, separado y desmontaje)",
    "G46699 - Venta al por mayor de basura, desechos y otros productos",
    "G469 - Venta al por mayor no especializados de otros productos",
    "G4690 - Venta al por mayor no especializados de otros productos",
    "G46900 - Venta al por mayor no especializados de otros productos",
    "G47 - Comercio al por menor: mantenimiento y reparación de vehículos automotores y motocicletas",
    "G471 - Venta al por menor en almacenes no especializados",
    "G4711 - Venta al por menor en almacenes no especializados, con surtido compuestos principalmente de alimentos, bebidas y tabaco",
    "G47111 - Venta al por menor de abarrotes (tiendas, kioscos, etc.)",
    "G47112 - Supermercados",
    "G47113 - Mini súper",
    "G4719 - Venta al por menor de otros productos en almacenes no especializados (almacenes por departamentos o secciones)",
    "G47191 - Venta al por menor en almacenes con surtido muy diverso",
    "G47192 - Venta al por menor en almacenes por departamentos",
    "G472 - Venta al por menor de alimentos, bebidas y tabaco en almacenes especializados",
    "G4721 - Venta al por menor de alimentos en almacenes especializados",
    "G47211 - Venta al por menor de frutas y verduras frescas o en conservas",
    "G47212 - Venta al por menor de productos lácteos y huevos",
    "G47213 - Venta al por menor de carnes y productos cárnicos (incluso las aves de corral)",
    "G47214 - Venta al por menor de pescado, mariscos y productos derivados",
    "G47215 - Venta al por menor de pan y productos de panaderías",
    "G47216 - Venta al por menor de productos de confitería",
    "G47219 - Venta al por menor de alimentos en almacenes especializados, n.c.p.",
    "G4722 - Venta al por menor de bebidas en almacenes especializados",
    "G47220 - Venta al por menor de bebidas en almacenes especializados",
    "G4723 - Venta al por menor de tabaco en almacenes especializados",
    "G47230 - Venta al por menor de tabaco en almacenes especializados",
    "G473 - Venta al por menor de vehículos automotores y motocicletas, sus partes y combustible para vehículos",
    "G4730 - Venta al por menor de combustible para veh?culos automotores",
    "G47300 - Venta al por menor de combustible para vehículos automotores",
    "G4731 - Venta al por menor de veh?culos automotores",
    "G47310 - Venta al por menor de vehículos automotores",
    "G4732 - Mantenimiento y reparación de vehículos automotores",
    "G47321 - Mantenimiento y reparación de vehículos automotores",
    "G47322 - Reparación de carrocerías (chapistería) para vehículos automotores",
    "G47323 - Lavado y lustrado de vehículos automotores",
    "G4733 - Venta al por menor de partes, piezas y accesorios de veh?culos automotores",
    "G47330 - Venta al por menor de partes, piezas y accesorios de vehículos automotores",
    "G4734 - Venta al por menor de motocicletas, sus partes, piezas y accesorios, mantenimiento y reparación",
    "G47341 - Venta al por menor de motocicletas y ciclomotores, sus partes, piezas y accesorios",
    "G47342 - Reparación y mantenimiento de motocicletas y ciclomotores",
    "G474 - Venta al por menor de equipos de TIC (Tecnología de Información y Comunicación) en almacenes especializados",
    "G4741 - Venta al por menor de computadoras, unidades perif?ricas, equipo de software y telecomunicaciones",
    "G47410 - Venta al por menor de computadoras, unidades periféricas, equipo de software y telecomunicaciones",
    "G4742 - Venta al por menor de equipo de audio y video en almacenes especializados",
    "G47420 - Venta al por menor de equipo de audio y video en almacenes especializados",
    "G475 - Venta al por menor de otro equipamiento para la casa, en almacenes especializados",
    "G4751 - Venta al por menor de textiles en almacenes especializados",
    "G47510 - Venta al por menor de textiles en almacenes especializados",
    "G4752 - Venta al por menor de art?culos de ferreter?a, pinturas y productos de vidrio en almacenes especializados",
    "G47520 - Venta al por menor de artículos de ferretería, pinturas y productos de vidrio en almacenes especializados",
    "G4753 - Venta al por menor de alfombras, cubiertas de pared y piso en almacenes especializados",
    "G47530 - Venta al por menor de alfombras, cubiertas de pared y piso en almacenes especializados",
    "G4759 - Venta al por menor de aparatos, muebles, artículos y equipos de uso domésticos en almacenes especializados",
    "G47591 - Venta al por menor de muebles domésticos",
    "G47592 - Venta al por menor de artículos de iluminación",
    "G47593 - Venta al por menor de utensilios domésticos y cubiertos, artículos de loza, vidrio, porcelana y cerámica",
    "G47594 - Venta al por menor de artículos hechos de madera, corcho y mimbre",
    "G47595 - Venta al por menor de electrodomésticos",
    "G47596 - Venta al por menor de instrumentos musicales y partituras",
    "G47597 - Venta al por menor de sistemas de seguridad, tales como dispositivos de candados, cajas fuertes y bóvedas, sin instalación o servicio de mantenimiento",
    "G47599 - Venta al por menor de aparatos, muebles, artículos y equipo de uso domésticos en almacenes especializados, n.c.p.",
    "G476 - Venta al por menor de productos culturales y de entretenimiento en almacenes especializados",
    "G4761 - Venta al por menor de libros, periódicos, artículos de papelería, materiales y equipos de oficina en almacenes especializados",
    "G47611 - Venta al por menor de libros de todo tipo, periódicos y artículos de papelería",
    "G47612 - Venta al por menor de periódicos, materiales y equipo de oficina",
    "G47619 - Venta al por menor de libros, periódicos, artículos de papelería, materiales y equipo de oficina, en almacenes especializados, n.c.p.",
    "G4762 - Venta al por menor de grabaciones de audio y video en almacenes especializados",
    "G47620 - Venta al por menor de grabaciones de audio y video en almacenes especializados",
    "G4763 - Venta al por menor de art?culos de deporte en almacenes especializados",
    "G47630 - Venta al por menor de artículos de deporte en almacenes especializados",
    "G4764 - Venta al por menor de juegos y de juguetes en almacenes especializados",
    "G47640 - Venta al por menor de juegos y de juguetes en almacenes especializados",
    "G477 - Venta al por menor de otros artículos en tiendas especializadas",
    "G4771 - Venta al por menor de productos textiles, prendas de vestir, calzado y artículos de cuero en almacenes especializados",
    "G47711 - Venta al por menor de productos textiles",
    "G47712 - Venta al por menor de prendas de vestir (excepto calzados)",
    "G47713 - Venta al por menor de calzados",
    "G47714 - Venta al por menor de artículos de cuero",
    "G47715 - Venta al por menor de tejidos (telas)",
    "G47719 - Venta al por menor de productos textiles, prendas de vestir, calzados y artículos de cuero en almacenes especializados, n.c.p. (boutique)",
    "G4772 - Venta al por menor de productos farmacéuticos, ortopédicos, medicinales , cosméticos y artículos de tocador en almacenes especializados",
    "G47721 - Venta al por menor de productos farmacéuticos y medicinales",
    "G47722 - Venta al por menor de artículos ortopédicos (ejm: camas, bastones.etc.)",
    "G47729 - Venta al por menor de productos farmacéuticos medicinales, ortopédicos, cosméticos y artículos de tocador en almacenes especializados, n.c.p.",
    "G4773 - Otros tipos venta al por menor de productos en almacenes especializados (ópticas)",
    "G47731 - Venta al por menor de equipos y/o artículos ópticos y de precisión actividades ópticas",
    "G47732 - Venta al por menor de flores, plantas, semillas y fertilizantes",
    "G47733 - Venta al por menor de mascotas y alimentos para mascotas",
    "G47734 - Venta al por menor de artículos de recuerdos religiosos y artesanías; actividades de galerías de arte comerciales",
    "G47735 - Venta al por menor de combustible para artículos domésticos, gas envasado, carbón y combustible de madera",
    "G47736 - Venta al por menor de armas y municiones",
    "G47739 - Venta al por menor de productos nuevos en almacenes especializados n.c.p, (productos no alimenticios, n.c.p.)",
    "G4774 - Venta al por menor de mercanc?as en almacenes de art?culos usados (Productos no alimenticios)",
    "G47740 - Venta al por menor de mercanc?as en almacenes de art?culos usados (Productos no alimenticios)",
    "G4775 - Venta al por menor de relojes, joyas y fantas?a fina",
    "G47750 - Venta al por menor de relojes, joyas y fantas?a fina",
    "G478 - Venta al por menor en puestos de ventas y mercados",
    "G4781 - Venta al por menor en puestos de ventas y mercados de alimentos, bebidas y de productos del tabaco",
    "G47810 - Venta al por menor en puestos de ventas y mercados de alimentos, bebidas y de productos del tabaco",
    "G4782 - Venta al por menor en puestos de ventas y mercados de art?culos, textiles, ropa,. Calzado y otras mercanc?as",
    "G47820 - Venta al por menor en puestos de ventas y mercados de art?culos, textiles, ropa,. Calzado y otras mercanc?as",
    "G4789 - Venta al por menor en puestos de ventas y mercados de loter?a y peri?dicos",
    "G47890 - Venta al por menor en puestos de ventas y mercados de loter?a y peri?dicos",
    "G479 - Comercio al por menor no realizado en almacenes, puestos de venta y mercados",
    "G4791 - Venta al por menor a trav?s de pedido por correo o v?a Internet",
    "G47910 - Venta al por menor a trav?s de pedido por correo o v?a Internet",
    "G4799 - Otros tipos de venta al por menor no realizada en almacenes, puestos de ventas y mercados, n.c.p.",
    "G47990 - Otros tipos de venta al por menor no realizada en almacenes, puestos de ventas y mercados, n.c.p.",
    "G48 - Comercio al por menor en Zonas Francas",
    "G481 - Venta al por menor a cambio de una retribución o por contrata en Zonas Francas",
    "G4810 - Venta al por menor a cambio de una retribuci?n o por contrata en Zonas Francas",
    "G48100 - Venta al por menor a cambio de una retribuci?n o por contrata en Zonas Francas",
    "G482 - Venta al por menor de materias primas agropecuarias y animales vivos en Zonas Francas",
    "G4820 - Venta al por menor de materias primas agropecuarias y animales vivos en Zonas Francas",
    "G48200 - Venta al por menor de materias primas agropecuarias y animales vivos en Zonas Francas",
    "G483 - Venta al por menor de alimentos, bebidas y tabaco en Zonas Francas",
    "G4830 - Venta al por menor de alimentos, bebidas y tabaco en Zonas Francas",
    "G48300 - Venta al por menor de alimentos, bebidas y tabaco en Zonas Francas",
    "G484 - Venta al por menor de efectos personales, enseres domésticos, artículos de joyerías, relojes y productos diversos para el consumidor en Zonas Francas",
    "G4841 - Venta al por menor de textiles, prendas de vestir, sus accesorios y calzado en Zonas Francas",
    "G48410 - Venta al por menor de textiles, prendas de vestir, sus accesorios y calzado en Zonas Francas",
    "G4842 - Venta al por menor de enseres dom?sticos en Zonas Francas",
    "G48420 - Venta al por menor de enseres dom?sticos en Zonas Francas",
    "G4843 - Venta al por menor de art?culos de joyer?a y relojes en Zonas Francas",
    "G48430 - Venta al por menor de art?culos de joyer?a y relojes en Zonas Francas",
    "G4849 - Venta al por menor de otros efectos personales y productos diversos para el consumidor en Zonas Francas",
    "G48490 - Venta al por menor de otros efectos personales y productos diversos para el consumidor en Zonas Francas",
    "G485 - Venta al por menor de maquinaria, equipos y materiales; vehículos automotores, motocicletas, sus partes, piezas y accesorios en Zonas Francas",
    "G4851 - Venta al por menor de computadoras, equipo inform?tico, perif?ricos y programas inform?ticos en Zonas francas",
    "G48510 - Venta al por menor de computadoras, equipo inform?tico, perif?ricos y programas inform?ticos en Zonas francas",
    "G4852 - Venta al por menor de equipo electr?nico, de telecomunicaciones, sus partes y piezas en Zonas Francas",
    "G48520 - Venta al por menor de equipo electr?nico, de telecomunicaciones, sus partes y piezas en Zonas Francas",
    "G4853 - Venta al por menor de maquinaria, equipo agr?cola, sus partes, piezas y accesorios en Zonas Francas",
    "G48530 - Venta al por menor de maquinaria, equipo agr?cola, sus partes, piezas y accesorios en Zonas Francas",
    "G4854 - Venta al por menor de veh?culos automotores en Zonas Francas",
    "G48540 - Venta al por menor de veh?culos automotores en Zonas Francas",
    "G4855 - Venta al por menor de partes, piezas y accesorios de veh?culos automotores en Zonas Francas",
    "G48550 - Venta al por menor de partes, piezas y accesorios de veh?culos automotores en Zonas Francas",
    "G4856 - Venta al por menor de motocicletas; sus partes, piezas y accesorios en Zonas Francas",
    "G48560 - Venta al por menor de motocicletas; sus partes, piezas y accesorios en Zonas Francas",
    "G4859 - Venta al por menor de otro tipo de maquinaria y equipo en Zonas Francas",
    "G48590 - Venta al por menor de otro tipo de maquinaria y equipo en Zonas Francas",
    "G486 - Venta al por menor especializada en Zonas Francas",
    "G4861 - Venta al por menor de combustibles s?lidos, l?quidos, gaseosos y de productos conexos en Zonas Francas",
    "G48610 - Venta al por menor de combustibles s?lidos, l?quidos, gaseosos y de productos conexos en Zonas Francas",
    "G4862 - Venta al por menor de metales y de minerales metal?feros en Zonas Francas",
    "G48620 - Venta al por menor de metales y de minerales metal?feros en Zonas Francas",
    "G4863 - Venta al por menor de materiales de construcci?n, art?culos de ferreter?a, equipo y materiales de fontaner?a y calefacci?n en Zonas Francas",
    "G48630 - Venta al por menor de materiales de construcci?n, art?culos de ferreter?a, equipo y materiales de fontaner?a y calefacci?n en Zonas Francas",
    "G4869 - Venta al por menor de basura, desecho y otros productos, n.c.p. en Zonas Francas",
    "G48690 - Venta al por menor de basura, desecho y otros productos, n.c.p. en Zonas Francas",
    "G489 - Venta al por menor de otros productos no especializada en Zonas Francas",
    "G4890 - Venta al por menor de otros productos no especializada en Zonas Francas",
    "G48900 - Venta al por menor de otros productos no especializada en Zonas Francas",
    "H - TRANSPORTE, ALMACENAMIENTO Y CORREO",
    "H4 - Transporte por v?a terrestre; transporte por tuber?as",
    "H49 - Transporte por vía terrestre; transporte por tuberías",
    "H491 - Transporte por vía férrea",
    "H4911 - Transporte de pasajeros por vía férrea",
    "H4912 - Transporte de carga por vía férrea",
    "H492 - Otros tipos de transporte por vía terrestre",
    "H4921 - Transporte terrestre de pasajeros del área urbana, suburbana o metropolitana",
    "H4922 - Otros transporte terrestre de pasajeros",
    "H4923 - Transporte de carga por carretera",
    "H4924 - Transporte de carga por carretera en Zonas Francas",
    "H493 - Transporte por tuberías",
    "H4930 - Transporte por tuberías",
    "H5 - Transporte por v?a acu?tica",
    "H50 - Transporte por vía acuática",
    "H501 - Transporte marítimo y de cabotaje",
    "H5011 - Transporte marítimo y de cabotaje de pasajeros",
    "H5012 - Transporte marítimo y de cabotaje de carga",
    "H502 - Transporte por vías de navegación interiores",
    "H5021 - Transporte de pasajeros por vías de navegación interiores",
    "H5022 - Transporte de carga, por vías de navegación interiores",
    "H51 - Transporte por vía aérea",
    "H511 - Transporte de pasajeros por vía aérea",
    "H5110 - Transporte de pasajeros por vía aérea",
    "H512 - Transporte de carga por vía aérea",
    "H5120 - Transporte de carga por vía aérea",
    "H52 - Depósito y actividades de transporte complementarias",
    "H521 - Depósito y almacenaje",
    "H5211 - Depósito y almacenaje",
    "H5212 - Depósitos y almacenaje en Zonas Francas",
    "H522 - Actividades complementarias de transporte",
    "H5221 - Actividades de servicio secundario de transporte por vía terrestre",
    "H5222 - Actividades de servicio secundario de transporte por vía acuática",
    "H5223 - Actividades de servicio secundario de transporte por vía aérea",
    "H5224 - Manipulación de carga",
    "H5225 - Manipulación de carga en Zonas Francas",
    "H5226 - Autoridad del Canal de Panamá (ACP)",
    "H5227 - Actividades de otras agencias de transporte realizadas en Zonas Francas",
    "H5229 - Otras actividades complementarias de transporte",
    "H53 - Correo y servicios de mensajería",
    "H531 - Correo",
    "H5310 - Correo",
    "H532 - Servicio de mensajería",
    "H5320 - Servicio de mensajería",
    "H533 - Correo y encomiendas en Zonas Francas",
    "H5330 - Correo y encomiendas en Zonas Francas",
    "I - HOTELES Y RESTAURANTES",
    "I5 - Hoteles, campamentos y otros tipos de hospedajes temporales",
    "I55 - Hoteles, campamentos y otros tipos de hospedajes temporales",
    "I551 - Actividades de alojamiento temporales",
    "I5510 - Actividades de alojamiento temporales",
    "I55101 - Servicio de alojamiento en hoteles",
    "I55102 - Servicio de alojamiento en pensiones y residenciales",
    "I55109 - Otros servicios de alojamiento (Push Bottom)",
    "I552 - Los terrenos para acampar, parques de vehículos recreativos y parques remolques",
    "I5520 - Los terrenos para acampar, parques de vehículos recreativos y parques remolques",
    "I559 - Otros alojamientos",
    "I5590 - Otros alojamientos",
    "I56 - Restaurantes, bares y cantinas",
    "I561 - Restaurantes, bares y cantinas",
    "I5611 - Restaurantes",
    "I56111 - Servicios en restaurantes",
    "I56112 - Servicios en restaurantes de comida rápida",
    "I56119 - Otros servicios de restaurantes, n.c.p.",
    "I5612 - Preparación y ventas de alimentos en la vía pública y puestos improvisados",
    "I562 - Abastecimientos de eventos y otras actividades de servicio de alimentos",
    "I5621 - Abastecimientos de eventos",
    "I5629 - Otras actividades de servicio de alimentación",
    "I563 - Actividades vinculadas al servicio de bebidas",
    "I5631 - Actividades vinculadas al servicio de bebidas",
    "I56311 - Servicios en bares, cantinas, discotecas y jardines",
    "I56312 - Servicios de refresquerías",
    "I56319 - Otros servicios de bebidas, n.c.p.",
    "I5632 - Preparación y venta de bebidas en la vía pública y puestos improvisados",
    "J - INFORMACIÓN Y COMUNICACIÓN",
    "J5 - Actividades de Edici?n",
    "J58 - Actividades de Edición",
    "J581 - Edición de libros, periódicos y otras actividades de publicación",
    "J5811 - Edición de libros",
    "J5812 - Edición de directorios y de listas de envío",
    "J5813 - Edición de periódicos, diarios y revistas",
    "J5819 - Otras Ediciones",
    "J582 - Edición de programas informáticos",
    "J5820 - Edición de programas informáticos",
    "J59 - Actividades de producción de películas, videocintas, programas de televisión, grabación, publicación de música y sonido",
    "J591 - Actividades de producción de películas, videocintas y programas de televisión",
    "J5911 - Actividades de producción de películas, videocintas y programas de televisión",
    "J5912 - Actividades posteriores a la producción de películas, video0 y programas de televisión",
    "J5913 - Actividades de distribución de películas, video y programas de televisión",
    "J5914 - Actividades de proyección de películas (salas de cine)",
    "J592 - Actividades de grabación y publicación de grabaciones sonoras",
    "J5920 - Actividades de grabación y publicación de grabaciones sonoras",
    "J6 - Actividades de Programaci?n y difusi?n",
    "J60 - Actividades de Programación y difusión",
    "J601 - Difusión de radio",
    "J6010 - Difusión de radio",
    "J602 - Programación y actividades de producción y difusión de televisión",
    "J6021 - Difusión de televisión",
    "J6022 - Programación y actividades de producción y difusión de televisión",
    "J61 - Telecomunicaciones",
    "J611 - Actividades de telecomunicaciones por cable",
    "J6110 - Actividades de telecomunicaciones por cable",
    "J612 - Actividades de telecomunicaciones inalámbricas",
    "J6120 - Actividades de telecomunicaciones inalámbricas",
    "J613 - Actividades de telecomunicaciones por satélite",
    "J6130 - Actividades de telecomunicaciones por satélite",
    "J619 - Otras actividades de telecomunicación",
    "J6190 - Otras actividades de telecomunicación",
    "J62 - Actividades de la tecnología de información y del servicio informático",
    "J620 - Actividades de la tecnología de información y del servicio informático",
    "J6201 - Actividades de programación informática",
    "J6202 - Actividades de consultoría informática y actividades de administración de medios informáticos",
    "J6209 - Otras actividades de tecnología de información y servicio de computadoras",
    "J63 - Actividades del servicio informático e informativo",
    "J631 - Portales Web, procesamiento de datos, hospedaje y actividades conexas",
    "J6311 - Procesamiento de datos, hospedaje y actividades conexas",
    "J6312 - Portales Web",
    "J639 - Otras actividades del servicio de información",
    "J6391 - Actividades de agencias de noticias",
    "J6399 - Otros servicios de información, n.c.p.",
    "K - ACTIVIDADES FINANCIERAS Y DE SEGUROS",
    "K6 - Servicios financieros, excepto seguros y fondos de pensiones",
    "K64 - Servicios financieros, excepto seguros y fondos de pensiones",
    "K641 - Intermediación monetaria",
    "K6411 - Banca Central",
    "K6412 - Otros tipos de intermediación monetaria en establecimientos ubicados en Zonas Francas",
    "K6419 - Otros tipos de intermediación monetaria (Banco Nacional de Panamá)",
    "K642 - Actividades de sociedades de control (holding)",
    "K6420 - Actividades de sociedades de control (holding)",
    "K643 - Fideicomisos, fondos y otras fuentes de financiamiento",
    "K6430 - Fideicomisos, fondos y otras fuentes de financiamiento",
    "K649 - Otras actividades financieras, excepto seguros y actividades de fondo de pensiones n.c.p.",
    "K6491 - Arrendamiento financiero (Leasing)",
    "K6492 - Otros tipos de crédito (casas de empe?os, financieras)",
    "K6499 - Otras actividades financieras, excepto seguros y fondos de pensiones",
    "K65 - Seguros, reaseguros y fondos de pensiones, excepto los planes de seguridad social de afiliación obligatoria",
    "K651 - Seguros",
    "K6511 - Seguros de vida",
    "K6512 - Seguros generales",
    "K6513 - Seguros generales en Zonas Francas",
    "K652 - Reaseguros",
    "K6520 - Reaseguros",
    "K653 - Fondos de pensión",
    "K6530 - Fondos de pensión",
    "K66 - Actividades financieras a los servicios financieros y actividades de seguros",
    "K661 - Actividades auxiliares de la Intermediación financiera, excepto seguros y fondos de pensiones",
    "K6611 - Administración de mercados financieros",
    "K6612 - Títulos de valor y contratos de corretaje de materias primas",
    "K6619 - Otras actividades auxiliares a las actividades de servicios financieros",
    "K662 - Actividades auxiliares de seguros y fondos de pensiones",
    "K6621 - Evaluación de riesgos y da?os",
    "K6622 - Actividades de los agentes y corredores de seguro",
    "K6629 - Otras actividades auxiliares de seguros y fondos de pensiones",
    "K663 - Actividades de administración de fondos",
    "K6630 - Actividades de administración de fondos",
    "L - ACTIVIDADES INMOBILIARIAS",
    "L6 - Actividades inmobiliarias",
    "L68 - Actividades inmobiliarias",
    "L681 - Actividades inmobiliarias con bienes propios o arrendados",
    "L6810 - Actividades inmobiliarias con bienes propios o arrendados",
    "L682 - Actividades inmobiliarias realizadas a cambio de una retribución o por contrata",
    "L6821 - Actividades inmobiliarias realizadas a cambio de una retribución o por contrata",
    "L6822 - Actividades inmobiliarias realizadas a cambio de una retribución o por contrata en Zonas Francas",
    "M - ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS",
    "M6 - Actividades jur?dicas y de contabilidad",
    "M69 - Actividades jurídicas y de contabilidad",
    "M691 - Actividades jurídicas",
    "M6910 - Actividades jurídicas",
    "M692 - Actividades de contabilidad, teneduría de libros y auditorias; asesoramiento en materia de impuestos",
    "M6921 - Actividades de contabilidad, teneduría de libros y auditorias; asesoramiento en materia de impuestos",
    "M6922 - Actividades de contabilidad, teneduría de libros y auditorias; asesoramiento en materia de impuestos en Zonas Francas",
    "M7 - Actividades de oficinas centrales, actividades de administraci?n de empresas y de consultor?a sobre administraci?n de empresas",
    "M70 - Actividades de oficinas centrales, actividades de administración de empresas y de consultoría sobre administración de empresas",
    "M701 - Actividades de oficinas centrales",
    "M7010 - Actividades de oficinas centrales",
    "M702 - Actividades de administración de empresas y de consultoría sobre administración de empresas",
    "M7021 - Actividades de administración de empresas y de consultoría sobre administración de empresas",
    "M7022 - Actividades de administración de empresas y de consultoría sobre administración de empresas en Zonas Francas",
    "M71 - Actividades de arquitectura e ingeniería, ensayos y análisis técnicos",
    "M711 - Actividades de arquitectura e ingeniería y actividades conexas de asesoramiento técnico",
    "M7110 - Actividades de arquitectura e ingeniería y actividades conexas de asesoramiento técnico",
    "M712 - Ensayos y análisis técnicos",
    "M7120 - Ensayos y análisis técnicos",
    "M72 - Investigación y desarrollo científicos",
    "M721 - Investigación y desarrollo experimental en el campo de las ciencias naturales y la ingeniería",
    "M7210 - Investigación y desarrollo experimental en el campo de las ciencias naturales y la ingeniería",
    "M722 - Investigación y desarrollo experimental en el campo de las ciencias sociales y las humanidades",
    "M7220 - Investigación y desarrollo experimental en el campo de las ciencias sociales y las humanidades",
    "M73 - Publicidad e investigación de mercados",
    "M731 - Publicidad",
    "M7310 - Publicidad",
    "M732 - Investigación de mercados y encuestas de opinión pública",
    "M7320 - Investigación de mercados y encuestas de opinión pública",
    "M74 - Otras actividades profesionales, científicas y técnicas",
    "M741 - Actividades especializadas de dise?o",
    "M7410 - Actividades especializadas de dise?o",
    "M742 - Actividades de fotografía",
    "M7420 - Actividades de fotografía",
    "M749 - Otras actividades profesionales, científicas y técnicas, n.c.p.",
    "M7490 - Otras actividades profesionales, científicas y técnicas, n.c.p.",
    "M75 - Actividades veterinarias",
    "M750 - Actividades veterinarias",
    "M7500 - Actividades veterinarias",
    "N - ACTIVIDADES ADMINISTRATIVAS Y SERVICIOS DE APOYO",
    "N7 - Actividades del alquiler y arrendamiento",
    "N77 - Actividades del alquiler y arrendamiento",
    "N771 - Renta y alquiler de vehículos automotores",
    "N7710 - Renta y alquiler de vehículos automotores sin conductor",
    "N772 - Renta y Alquiler de efectos personales y enseres domésticos, n.c.p.",
    "N7721 - Renta y alquiler de equipo de recreo y deportivo",
    "N7722 - Alquiler de cintas de vídeo y discos",
    "N7729 - Alquiler de otros efectos personales y enseres domésticos, n.c.p.",
    "N773 - Alquiler de otro tipo de maquinaria, equipo sin operador y mercancías tangibles",
    "N7731 - Alquiler de otro tipo de maquinaria, equipo sin operador y mercancías tangibles",
    "N7732 - Alquiler de otro tipo de maquinaria, equipo sin operador y mercancías tangibles en Zonas Francas",
    "N774 - Arrendamiento de propiedad intelectual y productos similares excepto derechos de autor",
    "N7740 - Arrendamiento de propiedad intelectual y productos similares excepto derechos de autor",
    "N78 - Actividades de las agencias de empleo",
    "N781 - Actividades de agencias de colocación de empleados",
    "N7810 - Actividades de agencias de colocación de empleados",
    "N782 - Actividades de agencias de trabajo temporal",
    "N7820 - Actividades de agencias de trabajo temporal",
    "N783 - Otro suministro de recursos humanos",
    "N7830 - Otro suministro de recursos humanos",
    "N79 - Actividades de las agencias de viajes, operadores turísticos y servicios de reserva relacionados",
    "N791 - Actividades de agencia de viajes y operadores turísticos",
    "N7911 - Actividades de agencias de viajes",
    "N7912 - Actividades de operadores turísticos",
    "N7913 - Actividades de agencias de viajes y operadores turísticos en Zonas Francas",
    "N799 - Otros servicios de reserva y actividades relacionadas",
    "N7990 - Otros servicios de reserva y actividades relacionadas",
    "N8 - Actividades de seguridad e investigaci?n",
    "N80 - Actividades de seguridad e investigación",
    "N801 - Actividades de seguridad privada",
    "N8010 - Actividades de seguridad privada",
    "N802 - Actividades de servicio de sistemas de seguridad",
    "N8020 - Actividades de servicio de sistemas de seguridad",
    "N803 - Actividades de investigación",
    "N8030 - Actividades de investigación",
    "N81 - Actividades de servicio a edificios y paisajes (jardines, áreas verdes)",
    "N811 - Actividades combinadas de apoyo a instalaciones",
    "N8110 - Actividades combinadas de apoyo a instalaciones",
    "N812 - Actividades de limpieza",
    "N8121 - Limpieza general de edificios",
    "N8122 - Limpieza general de edificios en Zonas Francas",
    "N8129 - Otras actividades de limpieza industrial y de edificios (fumigación)",
    "N813 - Actividades de servicio de mantenimiento y cuidado de paisajes (jardines, áreas verdes)",
    "N8130 - Actividades de servicio de mantenimiento y cuidado de paisajes (jardines, áreas verdes)",
    "N82 - Actividades de oficinas administrativas, soporte de oficinas y otras actividades de soporte de negocios",
    "N821 - Actividades de oficinas administrativas y de apoyo",
    "N8211 - Actividades combinadas de servicios administrativos de oficina",
    "N8219 - Fotocopiado, preparación de documentos y otro apoyo especializado de oficinas",
    "N822 - Actividades de centros de llamadas",
    "N8220 - Actividades de centros de llamadas (call center)",
    "N823 - Organización de convenciones y eventos comerciales",
    "N8230 - Organización de convenciones y eventos comerciales",
    "N829 - Actividades de servicio de apoyo a los negocios, n.c.p.",
    "N8291 - Actividades de agencias de cobranza y oficinas de crédito",
    "N8292 - Actividades de envase y empaque",
    "N8299 - Otras actividades de servicio de apoyo a los negocios, n.c.p.",
    "O - ADMINISTRACIÓN PÚBLICA Y DEFENSA; PLANES DE SEGURIDAD SOCIAL DE AFILIACIÓN OBLIGATORIA",
    "O8 - Administraci?n p?blica y defensa; planes de seguridad social de afiliaci?n obligatoria",
    "O84 - Administración pública y defensa; planes de seguridad social de afiliación obligatoria",
    "O841 - Administración del Estado y aplicación de la política económica y social de la comunidad",
    "O8411 - Actividades de la administración pública en general",
    "O8412 - Regulación de las actividades de organismos que prestan servicios sanitarios, educativos, culturales y otros servicios sociales, excepto servicios de seguridad social",
    "O8413 - Regulación y facilitación de la actividad económica",
    "O842 - Prestación de servicios a la comunidad en general",
    "O8421 - Relaciones exteriores",
    "O8422 - Actividades de defensa",
    "O8423 - Actividades de mantenimiento del orden público y de seguridad",
    "O843 - Actividades de planes de seguridad social de afiliación obligatoria",
    "O8430 - Actividades de planes de seguridad social de afiliación obligatoria",
    "P - ENSE?ANZA",
    "P8 - Ense?anza",
    "P85 - Ense?anza",
    "P851 - Ense?anza preprimaria y primaria",
    "P8510 - Ense?anza preprimaria y primaria",
    "P852 - Ense?anza secundaria",
    "P8521 - Ense?anza secundaria de formación general",
    "P8522 - Ense?anza secundaria de formación técnica y profesional",
    "P853 - Ense?anza superior",
    "P8530 - Ense?anza superior",
    "P854 - Otros tipos de ense?anza",
    "P8541 - Educación deportiva y recreativa",
    "P8542 - Educación cultural",
    "P8549 - Otros tipos de ense?anza, n.c.p.",
    "P855 - Servicios de apoyo educativo",
    "P8550 - Servicios de apoyo educativo",
    "Q - SERVICIOS SOCIALES Y RELACIONADOS CON LA SALUD HUMANA",
    "Q8 - Actividades relacionadas con la salud humana",
    "Q86 - Actividades relacionadas con la salud humana",
    "Q861 - Actividades de hospitales",
    "Q8610 - Actividades de hospitales",
    "Q862 - Actividades de médicos y odontólogos",
    "Q8620 - Actividades de médicos y odontólogos",
    "Q869 - Otras actividades relacionadas con la salud humana",
    "Q8690 - Otras actividades relacionadas con la salud humana (laboratorio clínico)",
    "Q87 - Instituciones residenciales de cuidado",
    "Q871 - Instalaciones de residencias con cuidado de enfermería",
    "Q8710 - Instalaciones de residencias con cuidado de enfermería",
    "Q872 - Instituciones dedicadas al tratamiento del retraso mental, los problemas de salud mental y el uso indebido de sustancias nocivas",
    "Q8720 - Instituciones dedicadas al tratamiento del retraso mental, los problemas de salud mental y el uso indebido de sustancias nocivas",
    "Q873 - Instituciones dedicadas al cuidado del adulto mayor y discapacitados",
    "Q8730 - Instituciones dedicadas al cuidado del adulto mayor y discapacitados",
    "Q879 - Otras instituciones residenciales de cuidado",
    "Q8790 - Otras instituciones residenciales de cuidado",
    "Q88 - Servicios sociales sin alojamiento",
    "Q881 - Servicios sociales sin alojamiento para el adulto mayor y discapacitados",
    "Q8810 - Servicios sociales sin alojamiento para el adulto mayor y discapacitados",
    "Q889 - Otras actividades de trabajo social sin alojamiento, n.c.p.",
    "Q8890 - Otras actividades de trabajo social sin alojamiento, n.c.p.",
    "R - ARTES, ENTRETENIMIENTO Y CREATIVIDAD",
    "R9 - Actividades de arte, entretenimiento y creatividad",
    "R90 - Actividades de arte, entretenimiento y creatividad",
    "R900 - Actividades de arte, entretenimiento y creatividad",
    "R9000 - Actividades de arte, entretenimiento y creatividad",
    "R91 - Bibliotecas, archivos, museos y otras actividades culturales",
    "R910 - Bibliotecas, archivos, museos y otras actividades culturales",
    "R9101 - Actividades de Bibliotecas y archivos",
    "R9102 - Actividades de museos y preservación de lugares históricos",
    "R9103 - Actividades de jardines botánicos, zoológicos y parques naturales",
    "R92 - Actividades de juego de azar y apuestas",
    "R920 - Actividades de juego de azar y apuestas",
    "R9200 - Actividades de juego de azar y apuestas",
    "R93 - Actividades deportivas, de diversión y esparcimiento",
    "R931 - Actividades deportivas",
    "R9311 - Administración de instalaciones deportivas",
    "R9312 - Actividades de clubes deportivos",
    "R9319 - Otras actividades deportivas",
    "R932 - Otras actividades de diversión y esparcimiento",
    "R9321 - Actividades de parques de atracciones y parques temáticos",
    "R9329 - Otras actividades de diversión y esparcimiento n.c.p.",
    "S - OTRAS ACTIVIDADES DE SERVICIO",
    "S9 - Actividades de asociaciones u organizaciones",
    "S94 - Actividades de asociaciones u organizaciones",
    "S941 - Actividades de organizaciones empresariales, profesionales y de empleadores",
    "S9411 - Actividades de organizaciones empresariales y de empleadores",
    "S9412 - Actividades de organizaciones profesionales",
    "S9413 - Actividades de organizaciones empresariales y de empleadores en Zonas Francas",
    "S942 - Actividades de sindicatos",
    "S9420 - Actividades de sindicatos",
    "S949 - Actividades de organizaciones religiosas",
    "S9491 - Actividades de organizaciones religiosas",
    "S9492 - Actividades de organizaciones políticas",
    "S9499 - Actividades de otras asociaciones, n.c.p. (clubes cívicos)",
    "S95 - Reparación y mantenimiento de computadoras y enseres de uso personal y doméstico",
    "S951 - Reparación y mantenimiento de computadoras y equipos comunicacionales",
    "S9511 - Reparación y mantenimiento de computadoras y equipo periférico",
    "S9512 - Reparación y mantenimiento de equipos comunicacionales",
    "S952 - Reparación y mantenimiento de enseres de uso personal y doméstico",
    "S9521 - Reparación y mantenimiento de aparatos de consumo eléctrico",
    "S9522 - Reparación y mantenimiento de aparatos domésticos, equipamiento de hogar y jardín",
    "S9523 - Reparación y mantenimiento de calzado y artículos de cuero",
    "S9524 - Reparación y mantenimiento de muebles y accesorios para el hogar",
    "S9529 - Reparación y mantenimiento de otros bienes personales y domésticos, n.c.p.",
    "S96 - Otras actividades de servicios",
    "S960 - Otras actividades de servicios",
    "S9601 - Lavado, secado y limpieza de prendas de tela y de piel",
    "S9602 - Actividades de peluquería y otros tratamientos de belleza",
    "S9603 - Funerales y actividades conexas",
    "S9609 - Otras actividades de servicios, n.c.p.",
    "T - ACTIVIDADES DE LOS HOGARES EN CALIDAD DE EMPLEADORES, ACTIVIDADES INDIFERENCIADAS DE PRODUCCIÓN DE BIENES Y SERVICIOS DE LOS HOGARES PARA USO PROPIO",
    "T9 - Actividades de los hogares en calidad de empleadores de personal dom?stico",
    "T97 - Actividades de los hogares en calidad de empleadores de personal doméstico",
    "T970 - Actividades de los hogares en calidad de empleadores de personal doméstico",
    "T9700 - Actividades de los hogares en calidad de empleadores de personal doméstico",
    "T98 - Actividades indiferenciadas de producción de bienes y servicios de los hogares privados para uso propio",
    "T981 - Actividades indiferenciadas de producción de bienes de los hogares privados para uso propio",
    "T9810 - Actividades indiferenciadas de producción de bienes de los hogares privados para uso propio",
    "T982 - Actividades indiferenciadas de producción de servicios de los hogares privados para uso propio",
    "T9820 - Actividades indiferenciadas de producción de servicios de los hogares privados para uso propio",
    "U - ACTIVIDADES DE ORGANIZACIONES Y ÓRGANOS EXTRATERRITORIALES Y ACTIVIDADES NO DECLARADAS",
    "U9 - Actividades de organizaciones y ?rganos extraterritoriales y actividades no declaradas",
    "U99 - Actividades de organizaciones y órganos extraterritoriales y actividades no declaradas",
    "U990 - Actividades de organizaciones y órganos extraterritoriales",
    "U9900 - Actividades de organizaciones y órganos extraterritoriales",
    "U991 - Actividades de organizaciones y órganos extraterritoriales",
    "U9910 - Actividades de organizaciones y órganos extraterritoriales",
];